import { NgxsDataRepository } from '@angular-ru/ngxs/repositories';
import { Computed, Persistence, StateRepository } from '@angular-ru/ngxs/decorators';
import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import * as dayjs from 'dayjs';
import dayjs from 'dayjs';
import { Auth, AuthStateInterface, LoginByEmailOptions, LoginOptions } from './auth.interfaces';
import { LogoutService } from '../services/logout.service';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Persistence()
@StateRepository()
@State({
  name: 'eng_auth',
})
@Injectable({
  providedIn: 'any',
})
export class AuthState extends NgxsDataRepository<AuthStateInterface> {
  constructor(
    private httpClient: HttpClient,
    private logoutService: LogoutService,
  ) {
    super();
  }

  @Computed()
  get isAuthorizationExpired(): boolean {
    // return this.snapshot?.access_token ? dayjs().isAfter(dayjs(this.snapshot.expires_at)) : true;
    return this.snapshot?.access_token ? dayjs().isAfter(dayjs(this.snapshot.expires_at)) : true;
  }

  @Computed()
  get authorization(): { Authorization: string } | object {
    return this.snapshot?.access_token
      ? {
          Authorization: `${this.snapshot.token_type} ${this.snapshot.access_token}`,
        }
      : {};
  }

  // @DataAction()
  auth(phone: string, token?: string | null): Observable<Auth> {
    phone = phone.replace('+', '');
    return this.httpClient.post<Auth>('{api}/auth', { phone, token });
  }

  // @DataAction()
  authByEmail(email: string, token?: string | null): Observable<Auth> {
    return this.httpClient.post<Auth>('{api}/auth', { email, token });
  }

  // @DataAction()
  login(options: LoginOptions): Observable<AuthStateInterface> {
    options.phone = options.phone.replace('+', '');
    return this.httpClient
      .post<AuthStateInterface>('{api}/auth/login', options)
      .pipe(tap((login) => this.setState(login)));
  }

  // @DataAction()
  loginByEmail(options: LoginByEmailOptions): Observable<AuthStateInterface> {
    return this.httpClient
      .post<AuthStateInterface>('{api}/auth/login', options)
      .pipe(tap((login) => this.setState(login)));
  }

  // @DataAction()
  logout() {
    return this.httpClient.post('{api}/auth/logout', {}).pipe(
      finalize(() => {
        this.reset();
        this.logoutService.logout();
      }),
    );
  }
}
